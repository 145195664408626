$blue:    #b747b2 !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
$red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
$green:   #28a745 !default;
// $teal:    #20c997 !default;
$cyan:    #43c7f1 !default;
$grey:    #999 !default;

@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');

@font-face { font-family: BunueloLight; src: url(../font/BunueloCleanPro-Light.ttf); }

@font-face { font-family: BunueloRegular; src: url(../font/BunueloCleanPro-Regular.ttf); }

@font-face { font-family: BunueloSemiBold; src: url(../font/BunueloCleanPro-SemiBold.otf); }

@font-face { font-family: BunueloExtraBold; src: url(../font/BunueloCleanPro-ExtraBold.ttf); }

$font: 'BunueloRegular';
$fontxtrabold: 'BunueloExtraBold';
$fontsemibold: 'BunueloSemiBold';

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

@import "./sidebar";

body{font-family: $font;}

#root, body, html {
  min-height: 100% !important;
  height: 100%;
}

.fontBunuelo{font-family: $font;}

.fontBunueloSemiBold{font-family: $fontsemibold; color: #000; font-size: 16px}

.highcharts-button, .highcharts-legend, .highcharts-container > svg > text {display: none;}

.textActive{font-family: $fontsemibold; color: $green; font-size: 14px}

.textInactive{font-family: $fontsemibold; color: $red; font-size: 14px}

.c-pointer{cursor: pointer !important;}

.c-default{cursor: default !important;}

.material-icons.md-dark{color: inherit;}

.blur{ -webkit-filter: blur(3px); -moz-filter: blur(3px); -o-filter: blur(3px); -ms-filter: blur(3px); filter: blur(3px); }

.bg-transparent{background: transparent;}

.not-underline{text-decoration: solid !important;}

button{
    padding: 10px 20px !important;
    font-size: 12px !important;
}

.no-hover { text-decoration: none !important; &:hover{ text-decoration: none; }}

/* Card List Body */

.card-list-body{

    &.inactive{
        font-style: italic;
        text-decoration: line-through;
        color: #d44950;

        a{
            font-style: inherit;
            color: #d44950 !important;
        }
    }

    .card-body{
        font-size: 14px;

        i{ font-size: 16px; }

        span{ line-height: 0; }

        a{ text-decoration: underline; }

    }
}

// Component Modal

.simple-modal{

    &.open{
        display: block;
    }

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    overflow: auto;
    display: block;
    margin: auto;
    background: rgba(0,0,0,.6);
    padding: 1rem .25rem;
    display: none;
}

// Pagination
.pagination{
  .page-item{
      a{
          padding: 10px 20px;
          font-size: 12px;
          margin-left: .1rem;
      }
      a[disabled]{
          opacity: .8;
          cursor: no-drop;
      }
  }
}

.page-link{
  padding: 10px 20px;
  background: $primary;
  color: $white;
  font-size: 12px;
}
.page-link[disabled]{
  opacity: .8;
  cursor: no-drop;
}
// Login
.login {
    color: #737373;
    font-size: 16px;
    background-color: #fff;

    .navbar {
      padding: 1.5rem 2.5rem;

      .navbar-brand {
        img {
          width: 130px;
        }
      }
    }

    h1 {
      color: $primary;
      font-size: 2.8rem;
      font-family: $fontxtrabold;
    }

    form {
      input {
        border-radius: 0;
        border: 0;
        border-bottom: solid 1px #ddd;
        font-size: 1rem;

        &:focus {
          box-shadow: none;
          border-bottom-color: $cyan;
        }
      }

      button {
        background: $cyan;
        border-radius: 0;
        border-color: $cyan;
        padding: 0.8rem 3.5rem;
        font-family: $fontsemibold;

        &:hover{
          background: $cyan;
          border-color: $cyan;
          opacity: .8;
        }
      }

      a {
        color: $blue;
        font-size: 14px;

        &:hover {
          opacity: 0.7;
          transition: 0.7s;
          text-decoration: none;
        }
      }
    }

    span {
      color: #737373;
      font-size: 14px;

      a {
        color: #737373;
        &:hover {
          color: $blue;
          transition: 0.7s;
          text-decoration: none;
        }
      }
    }
  }

/* Tabs */

.nav-pills {
    border-radius: 0.5rem;
    background: rgba(0,0,0,.05);
    padding: 10px;

    .nav-item {

        a {
            color: rgba(33, 37, 41, 0.5);
            border-color: transparent;
            font-size: 14px;
            font-weight: 400;
            padding: .5rem;
        }

        a.active {
            color: $blue;
            background: #fff !important;
            border-radius: .5rem !important;
            font-weight: bold;
        }
    }
}

// Month
.calendar-month {
  .tasks {
    height: 200px;
    overflow-x: hidden;

    img {
      width: 15px;
      height: 15px;
    }
    span {
      font-size: 14px;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 5px;
    background: #fff;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 5px;
      background: #ddd;
    }
  }
}

.calendar-grid {
  .dropdown-filter {
    .dropdown-menu.show {
      left: -35px !important;
    }
  }
}

.avatars {
  padding-left: 0;
  list-style: none;
  margin: 0;

  li{
      display: inline-block;
  }
  .avatar {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      border: 2px solid #f8f9fa;
      background: #f8f9fa;
      color: #fff;
      margin-right: -15px;
  }
}


// Breadcumb

.breadcrumb{
  background: transparent;
  margin: 0;
  padding: 1rem 0;

  .breadcrumb-item{
      color: $grey;

      a{
          color: $primary;
      }
  }
}

// Loading

.loading{
  width:100%;
height:100%;
display:none;
  position:absolute;
  background: rgba(0,0,0,.8);

  &:before, &:after, div{
      width:200px;
      height:200px;
      top:50%;
      left:50%;
      display:block;
      position:absolute;
      border-radius:50%;
      border:4px solid $primary;
  }

  &:before{
      content:"";
      animation:scale 1s ease-in infinite;
  }
  &:after{
      content:"";
      animation:scale 2s ease-in infinite;
  }

  div{
      animation:scale 3s ease-in infinite;
  }
}

.loading.open{
  display: block;
  position: absolute;
  top: 0;
}

@keyframes scale{
	from{transform:translate(-50%,-50%) scale(0,0)}
	to{transform:translate(-50%,-50%) scale(1,1)}
}

#dropdown-basic-button22 {
  width: 150px;
  display: inline;
}

#dropdown-basic-button22\ border-select{
  border: 1px solid #ced4da !important;
  border-radius: .25rem !important;
  height: calc(1.5em + .75rem + 2px) !important;
  padding: .375rem .75rem !important;
  line-height: 1.5 !important;
  width: 150px;
  display: inline;
}

#dropdown-basic-button22\ border-select::after{
  margin-left: 5rem;
}

/* Form Add Feedback */

.ck.ck-reset.ck-editor.ck-rounded-corners{z-index: 0;}
.ck-editor__editable {height: 200px;}
.ck-file-dialog-button {
    display: none !important;
}
.css-yk16xz-control{ border-color: #ced4da !important; border-radius: 0 !important;}

/* Card Comments Tasks */
.comments-card {
  background: rgb(255, 240, 208);
  -webkit-box-pack: justify;
  width: 100%;
}

/* MOBILE */

@media (min-width: 768px) {
  .login {
    background: url(../img/bkg-login2.png);
    background-size: 90%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 768px) {
  .login {
    background-size: 90%;
    background-repeat: no-repeat;
  }
  .login .navbar {
    padding: 0rem;
  }
  #root > div > div > div > div.d-flex.align-items-start.col-sm-12.col-12 {
    align-items: center !important;
    justify-content: center !important;
  }
  #root > div > div > div > div.d-flex.align-items-center.justify-content-end.col-12 {
    text-align: center !important;
  }
  .fixed-top{
    position: relative;
  }
}
